@media only screen and (max-width: 767px) {
  html.mobileOpen #layout-wrapper .navbar-menu{
    margin-left: unset;
    width: 250px !important;
    .navbar-nav .nav-link span{
      display: unset;
    }
    .navbar-nav{
      position: sticky;
      top: 70px;
    }
  }
}

html .mobileStatus{
  display: none;
}

@media only screen and (max-width: 767px) {
  html .mobileStatus{
    display: block;
  }
  html .desktopStatus{
    display: none;
  }
}

.dcv-tooltip{
  position: relative;
  display: inline-block;
  .tooltiptext{
    visibility: hidden;
    width: 80px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -40px;
  }
  .dcv-width120{
    width: 120px;
    margin-left: -60px;
  }
  &:hover{
    .tooltiptext {
      visibility: visible;
    }
  }
}

html{

}



.dcv-modal{
  background-color: #0000008c;
}