@media only screen and (max-width: 767px) {
  html.mobileOpen #layout-wrapper .navbar-menu {
    margin-left: unset;
    width: 250px !important;
  }
  html.mobileOpen #layout-wrapper .navbar-menu .navbar-nav .nav-link span {
    display: unset;
  }
  html.mobileOpen #layout-wrapper .navbar-menu .navbar-nav {
    position: sticky;
    top: 70px;
  }
}
html .mobileStatus {
  display: none;
}

@media only screen and (max-width: 767px) {
  html .mobileStatus {
    display: block;
  }
  html .desktopStatus {
    display: none;
  }
}
.dcv-tooltip {
  position: relative;
  display: inline-block;
}
.dcv-tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -40px;
}
.dcv-tooltip .dcv-width120 {
  width: 120px;
  margin-left: -60px;
}
.dcv-tooltip:hover .tooltiptext {
  visibility: visible;
}

.dcv-modal {
  background-color: rgba(0, 0, 0, 0.5490196078);
}

